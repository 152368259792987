import React, { useEffect, useState } from 'react';
import Block from '../../adapters/helpers/Block';
import Image from '../Image/Image';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks';
import { AnalyticsConstants } from '../../adapters/helpers/ConstantsGA';
import { StartGuideBlockConstants } from '../../adapters/helpers/Constants';

const PageProgressBar = (props) => {
  const block = new Block(props);
  const { isXSmall, isSmall } = props;

  const hamburgerIcon = block.getFieldValue(
    StartGuideBlockConstants.hamburgerIcon
  );
  const closeIcon = block.getFieldValue(StartGuideBlockConstants.closeIcon);
  const classData = block.getFieldValue(StartGuideBlockConstants.classData);

  const [visible, setVisible] = useState(false);
  const [currentClassName, setCurrentClassName] = useState('');
  const [isAtTop, setIsAtTop] = useState(true);

  const toggleHamburger = () => {
    setVisible(!visible);
  };

  const scrollToElement = (className) => {
    const element = document.getElementById(`${className}`);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSectionClick = (className) => {
    setCurrentClassName(className);
    scrollToElement(className);
  };

  const isElementInViewport = (id) => {
    const element = document.getElementById(id);
    if (!element) {
      return false;
    }

    const rect = element.getBoundingClientRect();
    const isInViewport = rect.top <= 500;
    return isInViewport;
  };

  const setIsAtTopFunc = () => {
    const scrollPosition =
      window.pageYOffset || document.documentElement.scrollTop;
    setIsAtTop(scrollPosition <= 100);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsAtTopFunc();
      Object.values(classData).forEach((id) => {
        if (isElementInViewport(id)) {
          setCurrentClassName(id);
        }
      });
    };
    setIsAtTopFunc();
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className={`progress-tracker-container ${visible ? 'visible' : ''}`}>
      {isSmall || isXSmall ? (
        <>
          {!visible && (
            <button
              onClick={toggleHamburger}
              className='event_menu_click'
              data-action-detail={AnalyticsConstants.openMenu}
            >
              <Image image={hamburgerIcon} />
            </button>
          )}
          {visible && (
            <>
              <div className='progress-header-container'>
                <div className='progress-header-text'>iO2</div>
                <button onClick={toggleHamburger}>
                  <Image image={closeIcon} className='event_close_window' />
                </button>
              </div>

              <ul class='progress-tracker progress-tracker--vertical'>
                {classData &&
                  Object.entries(classData).map(([name, className]) => {
                    return (
                      <li class='progress-step'>
                        <button
                          onClick={() => {
                            scrollToElement(className);
                            toggleHamburger();
                          }}
                          className='progress-text event_button_click'
                          data-action-detail={name}
                          aria-label={name}
                        >
                          {name}
                        </button>
                        <div class='progress-marker'></div>
                      </li>
                    );
                  })}
              </ul>
            </>
          )}
        </>
      ) : (
        <ul class='progress-tracker progress-tracker--vertical'>
          {classData &&
            Object.entries(classData).map(([name, className]) => {
              return (
                <li
                  class={`progress-step ${
                    className == currentClassName ? 'is-active' : ''
                  } ${isAtTop ? 'scrolled-top' : ''}`}
                >
                  <button
                    onClick={() => handleSectionClick(className)}
                    className='progress-text event_button_click'
                    data-action-detail={name}
                    aria-label={name}
                  >
                    <span>{name}</span>
                    <div class='progress-marker'></div>
                  </button>
                </li>
              );
            })}
        </ul>
      )}
    </div>
  );
};

export default mediaQueryHOC(PageProgressBar);
